@media only screen and (device-width >= 320px) and (device-width <= 64em) and (-webkit-device-pixel-ratio >= 2) and (orientation: landscape) {
  .hero__image-container {
    display: none;
  }
}

@media only screen and (width <= 78.75em) {
  .hero__text {
    font-size: 6.4rem;
  }

  .about__process-container {
    max-width: 50rem;
    transform: translateX(0);
  }

  .about__skill__container {
    width: 50rem;
  }

  .about__skill__content {
    font-size: 1.2rem;
  }
}

@media only screen and (width <= 75em) {
  .hero__image-container {
    width: 38rem;
    height: 38rem;
  }

  .about__process-container {
    flex-direction: column;
  }

  .about__skill__lists {
    font-size: 1.8rem;
  }

  .about__next {
    rotate: 90deg;
  }
}

@media only screen and (width <= 78.75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (width <= 55.625em) {
  .hero__text {
    font-size: 5rem;
  }

  .about__content {
    font-size: 1.8rem;
  }

  .testimonial__background__image {
    display: none;
  }

  .testimonial-section {
    height: 135rem;
  }

  .testimonial {
    grid-template-columns: 1fr;
    gap: 2.4rem 0;
  }

  .project__subheader-title-email {
    font-size: 1.8rem;
  }

  .btn-mobile-nav {
    z-index: 9999;
    display: block;
  }

  .icon-mobile-nav {
    color: var(--primary-color);
  }

  .header__nav {
    background-color: #f5f5f5fa;
  }

  .header__main__nav {
    background-color: var(--background-color-light);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48vh;
    padding: 0;
    transition: all .5s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .header .nav-open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    z-index: 9999;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .header__main__nav {
    flex-direction: column;
    gap: 3.8rem;
    font-weight: bold;
  }

  .header__main__nav__links, .header__main__nav__links:visited {
    font-size: 3rem;
  }
}

@media only screen and (width <= 45.625em) {
  html {
    font-size: 50%;
  }

  .about__process-container {
    max-width: 25rem;
  }

  .about__skill__container {
    max-width: 20rem;
  }

  .project__email-container {
    flex-direction: column;
  }

  .project__subheader-title-email {
    font-size: 2.8rem;
  }

  .cta__section {
    height: 60rem;
    padding-top: 10rem;
  }
}

@media only screen and (width <= 35em) {
  .hero__image-container {
    width: 30rem;
    height: 30rem;
  }

  .secondary__section-container {
    width: 100%;
    padding: 2rem;
  }

  .about__content-container {
    padding: 1rem 0;
  }

  .about__process-container {
    max-width: 18rem;
  }

  .about__skill__container {
    max-width: 16rem;
  }

  .section-container {
    max-width: 100%;
    padding: 12rem 3rem 6rem;
  }
}

@media only screen and (width <= 26em) {
  html {
    font-size: 45.75%;
  }

  .section-container {
    max-width: 100%;
    padding: 14rem 3rem 6rem;
  }

  .secondary__section-container {
    max-width: 100%;
    padding: 6rem 3rem;
  }

  .hero__image-container {
    width: 28rem;
    height: 28rem;
  }

  .secondary__section-container {
    width: 100%;
    padding: .5rem;
  }

  .about__process-container {
    max-width: 20rem;
  }

  .about__skill__container {
    max-width: 16rem;
  }
}

/*# sourceMappingURL=index.12cd1833.css.map */
