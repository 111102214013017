/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .hero__image-container {
    display: none;
  }
}

/* calculate 1em 1250 / 16 */
/* 1260 */
@media only screen and (max-width: 78.75em) {
  .hero__text {
    font-size: 6.4rem;
  }
  .about__process-container {
    max-width: 50rem;
    transform: translateX(0);
  }
  .about__skill__container {
    width: 50rem;
  }

  .about__skill__content {
    font-size: 1.2rem;
  }
}

/* 1200px */

@media only screen and (max-width: 75em) {
  /* Hero section */
  .hero__image-container {
    width: 38rem;
    height: 38rem;
  }
  /* About section */
  .about__process-container {
    flex-direction: column;
  }

  .about__skill__lists {
    font-size: 1.8rem;
  }

  .about__next {
    rotate: 90deg;
  }
}

/* 1020px */

@media only screen and (max-width: 78.75em) {
  html {
    font-size: 56.25%;
  }
}

/* 890px */
/* Insert mobile nav here */
@media only screen and (max-width: 55.625em) {
  /* header section */
  .hero__text {
    font-size: 5rem;
  }
  /* About section */
  .about__content {
    font-size: 1.8rem;
  }
  /* Testimonial */
  .testimonial__background__image {
    display: none;
  }
  .testimonial-section {
    height: 135rem;
    /* height: 100vh; */
  }

  .testimonial {
    grid-template-columns: 1fr;
    row-gap: 2.4rem;
    column-gap: 0;
  }

  /* project */
  .project__subheader-title-email {
    font-size: 1.8rem;
  }

  /* mobile header */

  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .icon-mobile-nav {
    color: var(--primary-color);
  }

  .header__nav {
    background-color: rgb(245, 245, 245, 0.98);
  }

  .header__main__nav {
    /* background-color: rgba(255, 255, 255, 0.9); */
    background-color: var(--background-color-light);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 48vh;
    transform: translateX(100%);
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide navigation */
    /* Allows NO transitions at all */
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
  }

  .header .nav-open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
    z-index: 9999;
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .header__main__nav {
    flex-direction: column;
    font-weight: bold;
    gap: 3.8rem;
  }

  .header__main__nav__links,
  .header__main__nav__links:visited {
    font-size: 3rem;
  }
}

/* 730px */
@media only screen and (max-width: 45.625em) {
  html {
    font-size: 50%;
  }

  /* about section */
  .about__process-container {
    max-width: 25rem;
  }

  .about__skill__container {
    max-width: 20rem;
  }

  /* project section */
  .project__email-container {
    flex-direction: column;
  }

  .project__subheader-title-email {
    font-size: 2.8rem;
  }

  /* CTA SECTION */
  .cta__section {
    height: 60rem;
    padding-top: 10rem;
  }
}

/* 560px */
@media only screen and (max-width: 35em) {
  /* hero */
  .hero__image-container {
    width: 30rem;
    height: 30rem;
  }

  .secondary__section-container {
    width: 100%;
    padding: 2rem 2rem 2rem 2rem;
  }

  /* about */
  .about__content-container {
    padding: 1rem 0;
  }
  .about__process-container {
    max-width: 18rem;
  }

  .about__skill__container {
    max-width: 16rem;
  }

  .section-container {
    max-width: 100%;
    padding: 12rem 3rem 6rem 3rem;
  }
}

/* 417px */

@media only screen and (max-width: 26em) {
  html {
    font-size: 45.75%;
  }

  .section-container {
    max-width: 100%;
    padding: 14rem 3rem 6rem 3rem;
  }

  .secondary__section-container {
    max-width: 100%;
    padding: 6rem 3rem 6rem 3rem;
  }

  .hero__image-container {
    width: 28rem;
    height: 28rem;
  }

  .secondary__section-container {
    width: 100%;
    padding: 0.5rem;
  }

  .about__process-container {
    max-width: 20rem;
  }

  .about__skill__container {
    max-width: 16rem;
  }
}
